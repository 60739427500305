body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global scrollbar styles */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #2d3748; /* Track color */
}

::-webkit-scrollbar-thumb {
  background: #4a5568; /* Thumb color */
  border-radius: 5px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #718096; /* Thumb color on hover */
}

body {
  overflow-y: scroll; /* Ensures vertical scrollbar is always visible */
}
